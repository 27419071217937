$(document).ready(function () {


  // dropdown open and hide when click outside
  const
    links = '.js-dropdown-link',
    innerElements = '.js-dropdown';

  $(document).on('click', links, function (e) {
    if ($(this).hasClass('active')) {
      $(links).filter('a[href="' + this.hash + '"]').removeClass('active');
      $(innerElements).filter(this.hash).removeClass('open').attr('aria-expanded', 'false');
    } else {
      $(links).removeClass('active').filter('a[href="' + this.hash + '"]').addClass('active');
      $(innerElements).removeClass('open').attr('aria-expanded', 'false').filter(this.hash).addClass('open').attr('aria-expanded', 'true');
    }
    e.preventDefault();
  });

  // dropdown close
  const dropdownCloseFunction = function name() {
    $(links).removeClass('active');
    $(innerElements).removeClass('open').attr('aria-expanded', 'false');
  };

  $(document).on('click touchstart', function (e) {
    if ((!$(links).add(innerElements).is(e.target) && $(links).add(innerElements).has(e.target).length === 0)) {
      dropdownCloseFunction();
    }
  });

  $(document).on('keyup', function (e) {
    if (e.keyCode === 27) {
      dropdownCloseFunction();
    }
  });


  // custom select
  if ($.fn.select2) {
    $('.js-select').select2({
      minimumResultsForSearch: Infinity
    });
  }


  // modal
  const
    modal = '.js-modal',
    modalWrap = '.js-modal-wrap',
    modalOpen = '.js-modal-link',
    modalClose = 'js-modal-close';

  if ($(modalOpen).length) {

    // modal ready
    $(document.body).append('<div class="modal js-modal" role="dialog" aria-labelledby="js-modal-title" aria-modal="true" tabindex="-1" id="modal"><div class="modal__hold"><div class="modal__bg js-modal-close"></div><div class="js-modal-wrap"></div></div><span tabindex="0" aria-hidden="true" onfocus="document.getElementById(\'modal\').focus()"></span></div>');

    // modal open
    $(document).on('click', modalOpen, function (e) {
      // get scrollbar width
      const scrollbarWidth = (window.innerWidth - $(window).width());

      $.ajax({
        type: 'GET',
        url: $(this).attr('href'),
        success: function (data) {
          $(modal).addClass('open').find(modalWrap).html(data);
          $('body').addClass('no-scroll');
          $('body').css('padding-right', scrollbarWidth);
        },
        error: function () {
          alert('fail');
        }
      });
      e.preventDefault();
    });

    // modal close
    const modalCloseFunction = function name() {
      $(modal).removeClass('open').one('transitionend', function () {
        $(modalWrap).empty();
        $('body').removeClass('no-scroll');
        $('body').css('padding-right', 0);
      });
    };

    $(document).on('click', function (e) {
      if ($(e.target).hasClass(modalClose)) {
        modalCloseFunction();
        e.preventDefault();
      }
    });

    $(document).on('keyup', function (e) {
      if (e.keyCode === 27) {
        modalCloseFunction();
      }
    });

  }


});
